import PropTypes from 'prop-types'

import * as S from './styles'

const ButtonRound = ({ children, isDisabled, onClick, ...props }) => {
  const handleClick = (event, ...args) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
    }
    onClick(event, ...args)
  }

  return (
    <S.Button $isDisabled={isDisabled} onClick={handleClick} {...props}>
      {children}
    </S.Button>
  )
}

ButtonRound.defaultProps = {
  onClick: () => {},
}

ButtonRound.propTypes = {
  /** Label of button. */
  children: PropTypes.node.isRequired,
  /** Disabled state. */
  isDisabled: PropTypes.bool,
  /** onClick Function. */
  onClick: PropTypes.func,
}

export default ButtonRound
