import BaseButton from '@pretto/bricks/components/buttons/Button'
import ButtonRound from '@pretto/bricks/components/buttons/ButtonRound'
import BaseInput from '@pretto/bricks/components/form/BaseInput'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, Grid as GridComponent } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import BasePrettoSearch from '@pretto/bricks/website/about/components/PrettoSearch'
import RichContent from '@pretto/bricks/website/about/components/RichContent'
import BaseImage from '@pretto/bricks/website/shared/components/Image'
import BaseReasonToBelieve from '@pretto/bricks/website/shared/components/ReasonToBelieve'

import { Check } from '@pretto/picto'

import styled, { css } from 'styled-components'

export const Button = styled(BaseButton).attrs(({ theme }) => theme.designSystem === 'reveal' && { isSecondary: true })`
  margin-top: ${g(3)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`

export const Container = styled.div`
  padding-bottom: ${g(24)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(9)};
  }
`

export const Description = styled(RichContent)`
  margin-top: ${g(3)};
  color: ${({ theme }) => theme.colors.white};
`

export const Grid = styled(GridComponent)`
  background-color: ${({ theme }) => theme.colors.primary1};
`

export const Image = styled(BaseImage).attrs({ options: { height: ng(36), width: ng(36) } })`
  display: block;
  height: ${g(25)};
  width: ${g(25)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(36)};
    width: ${g(36)};
  }
`

export const ImageContainer = styled.div`
  ${column([2, 4])};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([7, 3])};
    justify-content: flex-end;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 4])};
  }
`

export const Main = styled.div`
  ${column([2, 4])};
  padding: ${g(4)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([2, 4]) : column([2, 5]))};
    padding-bottom: ${g(9)};
    padding-top: ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([3, 4]) : column([3, 6]))};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ $isSimulatorDisplayed }) => $isSimulatorDisplayed && column([3, 5])};
  }
`

export const MiniSentences = styled.div`
  ${column([2, 4])};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([6, 4])};
    justify-content: flex-end;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([9, 4])};
    margin-bottom: ${g(4)};
  }
`

export const PrettoSearch = styled(BasePrettoSearch)`
  margin-top: ${g(3)};
`

export const ReasonToBelieve = styled(BaseReasonToBelieve)`
  ${column([2, 4])};
  margin-bottom: ${g(-24)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    margin-bottom: ${g(-9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const Title = styled(BaseTypo).attrs({ forwardedAs: 'h1', typo: 'heading48' })`
  color: ${({ theme }) => theme.colors.white};
`

// FOLLOWING P2
export const EmailWrapper = styled.div`
  margin-top: ${g(4)};
`

// Email type
export const InputContainer = styled.div`
  margin: ${g(2)} 0;
`
export const Submitted = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${g(1)};
  padding: ${g(2)};
  background-color: ${({ theme }) => theme.colors.primary4};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transform: translateX(${g(-6)});
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

  ${({ $isEntered }) =>
    $isEntered &&
    css`
      opacity: 1;
      transform: translateX(0);
    `}
`
export const SubmittedCheck = styled(Check)`
  fill: ${({ theme }) => theme.colors.primary1};
  margin-right: ${g(2)};
  width: ${g(3)};
  height: ${g(3)};
`
export const SubmittedText = styled(BaseTypo).attrs({ typo: 'bodyMedium' })`
  color: ${({ theme }) => theme.colors.primary1};
  flex-grow: 1;
  margin-right: ${g(2)};
`
export const SubmittedClose = styled(ButtonRound)`
  background-color: ${({ theme }) => theme.colors.primary3};
  box-shadow: none;
  height: ${g(4)};
  width: ${g(4)};

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary3};
  }
`

export const Input = styled(BaseInput).attrs({ isMessageTips: true, isSingle: true })``
export const LegalLinks = styled(BaseTypo).attrs({ forwardedAs: 'p', typo: 'caption' })`
  color: ${({ theme }) => theme.colors.white};
`
