import * as helpers from '@pretto/bricks/components/helpers'
import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Button = styled.button.attrs(({ $isDisabled }) => ({
  disabled: $isDisabled ? true : null,
  type: 'button',
}))`
  ${helpers.boxShadow('card')};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${g(5)};
  justify-content: center;
  transition: color 400ms ease-in-out, background-color 400ms ease-in-out, box-shadow 400ms ease-in-out;
  width: ${g(5)};

  &:disabled,
  &:focus,
  &:hover {
    ${helpers.boxShadow(0)};
    background-color: ${({ theme }) => theme.colors.neutral4};
    color: ${({ theme }) => theme.colors.neutral1Hover};
    outline: 0px transparent;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.neutral2};
    cursor: not-allowed;
  }
`
