import MiniSentences from '@pretto/bricks/website/shared/components/MiniSentences'

import { TimesBold } from '@pretto/picto'
import { useSentences } from '@pretto/website/src/utilities'

import PropTypes from 'prop-types'
import { memo } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const HeroN2 = ({
  buttonLabel,
  buttonUrl,
  checks,
  description,
  fieldProps,
  image,
  isCheckSectionDisplayed,
  isSimulatorDisplayed,
  isSubmitted,
  onCloseSubmitted,
  projectKind,
  promises,
  simulatorTabs,
  title,
  type,
  ...props
}) => {
  const sentencesProps = useSentences({ checks, tabs: projectKind, tabsData: simulatorTabs })
  const miniSentencesProps = { ...sentencesProps, isCheckSectionDisplayed }

  const renderComponent = type => {
    switch (type) {
      case 'button':
        return <S.Button href={buttonUrl}>{buttonLabel}</S.Button>

      case 'ebook':
      case 'newsletter':
        return (
          <S.EmailWrapper>
            <S.InputContainer>
              <S.Input {...fieldProps} />
              <Transition
                in={isSubmitted}
                unmountOnExit
                timeout={{
                  enter: 10,
                  exit: 300,
                }}
              >
                {transitionState => (
                  <S.Submitted $isEntered={transitionState === 'entered'}>
                    <S.SubmittedCheck />
                    <S.SubmittedText>Vous êtes abonné à la newsletter</S.SubmittedText>
                    <S.SubmittedClose onClick={onCloseSubmitted}>
                      <TimesBold />
                    </S.SubmittedClose>
                  </S.Submitted>
                )}
              </Transition>
            </S.InputContainer>
            <S.LegalLinks>
              En vous inscrivant, vous acceptez nos conditions d’utilisation et notre politique de confidentialité.
            </S.LegalLinks>
          </S.EmailWrapper>
        )

      case 'pretto_search':
        return <S.PrettoSearch />

      default:
        return null
    }
  }

  return (
    <S.Container {...props}>
      <S.Grid>
        <S.Main $isSimulatorDisplayed={isSimulatorDisplayed}>
          <S.Title>{title}</S.Title>
          <S.Description allowedTypes={['strong']}>{description}</S.Description>
          {renderComponent(type)}
        </S.Main>

        {isSimulatorDisplayed ? (
          <S.MiniSentences>
            <MiniSentences {...miniSentencesProps} />
          </S.MiniSentences>
        ) : (
          <S.ImageContainer>
            <S.Image path={image} />
          </S.ImageContainer>
        )}

        <S.ReasonToBelieve promises={promises} />
      </S.Grid>
    </S.Container>
  )
}

HeroN2.propTypes = {
  /** Label of the button. */
  buttonLabel: PropTypes.string,
  /** Url of the button. */
  buttonUrl: PropTypes.string,
  /** List of the checks in the simulator. */
  checks: PropTypes.array,
  /** Sub Text of Hero */
  description: PropTypes.string.isRequired,
  /** Props of field component. */
  fieldProps: PropTypes.object,
  /** Path of illustration. */
  image: PropTypes.string,
  /** Is the check section displayed in the simulator ? */
  isCheckSectionDisplayed: PropTypes.bool,
  /** Is simulator displayed ? */
  isSimulatorDisplayed: PropTypes.bool,
  /** Boolean for form validation. */
  isSubmitted: PropTypes.bool,
  /** Call back function after submit */
  onCloseSubmitted: PropTypes.func,
  /** List of the projectKinds in the simulator tabs */
  projectKind: PropTypes.array,
  /** RTB promises. */
  promises: PropTypes.array.isRequired,
  /** Data about the simulator tabs. */
  simulatorTabs: PropTypes.array,
  /** Title of element */
  title: PropTypes.string.isRequired,
  /** The type of component to display */
  type: PropTypes.oneOf(['button', 'ebook', 'newsletter', 'pretto_search']),
}

export default memo(HeroN2)
